<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div class="bar-back d-flex justify-content-between align-items-center">
        <div id="top"></div>
        <router-link to="/member/lottery" class="mr-auto"
          ><i class="fas fa-chevron-left"></i> ย้อนกลับ</router-link
        >
        <a
          href="javascript:;"
          class="btn btn-outline-secondary btn-sm mr-1 text-dark"
          data-toggle="modal"
          data-target="#rule-yeekee"
          >กติกา</a
        >
      </div>
      <div
        class="p-2 w-100 bg-light main-content align-self-stretch"
        style="min-height: calc((100vh - 150px) - 50px)"
      >
        <div
          class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
        >
          <div class="lotto-title">
            <h4><i class="fas fa-award"></i> ผลรางวัล</h4>
          </div>
        </div>
        <div class="bgwhitealpha shadow-sm rounded p-2 h-100 xtarget mb-5">
          <h6>
            <i class="fas fa-crown"></i> หวยรัฐบาลไทย
            <span class="badge badge-dark">17 Jan 2021</span>
          </h6>
          <div class="card border-dark text-center mb-2">
            <div class="card-header text-danger p-1">รางวัลที่ 1</div>
            <div class="card-body p-1">
              <h3 class="card-text">384395</h3>
            </div>
          </div>
          <div class="row">
            <div class="col pr-1">
              <div class="card border-dark text-center">
                <div class="card-header text-danger p-1">3ตัวหน้า</div>
                <div class="card-body p-1">
                  <h5 class="card-text">653,367</h5>
                </div>
              </div>
            </div>
            <div class="col px-1">
              <div class="card border-dark text-center">
                <div class="card-header text-danger p-1">สามตัวหลัง</div>
                <div class="card-body p-1">
                  <h5 class="card-text">878,566</h5>
                </div>
              </div>
            </div>
            <div class="col pl-1">
              <div class="card border-dark text-center">
                <div class="card-header text-danger p-1">2ตัวล่าง</div>
                <div class="card-body p-1">
                  <h5 class="card-text">15</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
